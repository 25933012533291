import {
  CREATE_DATE,
  CREATE_DATE_UPDATE,
  DELETE_DATE,
  GET_ALL_DATES,
  UPDATE_DATE,
} from "../actions/date.action";

const initialState = {};

export default function dateReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_DATES:
      return action.payload;
    case CREATE_DATE:
      return { ...state, newDate: action.payload.newDate };
    case CREATE_DATE_UPDATE:
      return { ...state, newDate: action.payload.newDate };
    case UPDATE_DATE:
      return { ...state, updateDate: action.payload.editedDate };
    case DELETE_DATE:
      return { ...state, deleteDate: action.payload.deleteDate };
    default:
      return state;
  }
}

export const selectDates = (state) => state.dateReducer.dates;
