import GoogleCalendar from "./GoogleCalendar";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../reducers/auth.reducer";
import Agenda from "./Agenda";
import "../styles/calendar.css";

const Calendar = () => {
  const loggedIn = useSelector(selectIsLoggedIn);
  if (!loggedIn) {
    return <Navigate to="/sign_in" />;
  }
  return (
    <section className="calendar">
      <GoogleCalendar />
      <Agenda />
    </section>
  );
};

export default Calendar;
